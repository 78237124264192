




























import { Component, Prop, Vue } from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';

import GoBack from '@/components/GoBack/index.vue';

import MenuAccordion from '@/assets/icons/MenuAccordion.vue';

import store from '@/store';

@Component({
  components: {
    ExLoading,
    GoBack,
    MenuAccordion
  }
})
export default class ContainerSidebar extends Vue {
  @Prop({ required: false }) url!: string;
  @Prop({ default: false }) btnClose!: boolean;
  @Prop({ default: true }) hasBook!: boolean;

  mounted() {
    store.commit('setSidebar', true);
    store.commit('setSidebarExistence', true);
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get decreaseSidebar() {
    return !store.getters.decreaseSidebar;
  }

  get sidebarExistence() {
    return store.getters.sidebarExistence;
  }

  changeSidebar() {
    store.commit('setDecreaseSidebar', !store.getters.decreaseSidebar);
  }

  buttonBackClicked() {
    this.$emit('handle-back-button-click');
  }

  get goToBack() {
    if (this.url) {
      return this.url;
    }

    if (store.getters.previousPageRoute) return store.getters.previousPageRoute;

    return '/dashboard';
  }
}
